import React from 'react';
import commingSoon from '../../img/Galllary/commingSoon.webp'

const CommingSoon = () => {
    return (
        <div className=''>
            <img className='mb-20 mt-36 m-auto' src={commingSoon} alt="" />
        </div>
    );
};

export default CommingSoon;